<template>
  <div>
    <VTitle :title="$t('app.notifications')" class="route-title" />

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
        <!-- BEGIN: Inbox Menu -->
        <div class="box p-5" :class="{ 'intro-y': hasAnimation }">
          <div
            class="flex cursor-pointer items-center px-3 py-2 rounded-md"
            :class="{
              'bg-theme-1 text-white dark:bg-dark-1 font-medium': isTabSelected(
                TABS.ALL
              )
            }"
            @click="onClickUpdateTab(TABS.ALL)"
          >
            <div class="flex items-center px-3 py-2 rounded-md">
              <i class="fal fa-inbox mr-2" />

              {{ $t("app.all") }}
            </div>
          </div>

          <div
            class="flex cursor-pointer items-center px-3 py-2 mt-2 rounded-md"
            :class="{
              'bg-theme-1 text-white dark:bg-dark-1 font-medium': isTabSelected(
                TABS.UNREAD
              )
            }"
            @click="onClickUpdateTab(TABS.UNREAD)"
          >
            <div class="flex items-center px-3 py-2 rounded-md">
              <i class="fal fa-envelope mr-2" />

              {{ $t("app.unread") }}
            </div>
          </div>

          <div class="border-t border-gray-300 mt-4 pt-4">
            <div
              v-for="(filter, index) in filters"
              :key="`filter-${index}`"
              class="flex justify-between cursor-pointer items-center px-3 py-2 truncate"
              :class="{ 'mt-2': index !== 0 }"
              @click="onClickUpdateFilter(filter.type)"
            >
              <div class="flex items-center">
                <div
                  v-if="isFilterSelected(filter.type)"
                  class="w-2 h-2 bg-theme-11 rounded-full mr-3"
                />

                {{ filter.text }}
              </div>

              <div>
                {{ filter[currentTab] }}
              </div>
            </div>
          </div>
        </div>
        <!-- END: Inbox Menu -->
      </div>

      <!-- BEGIN: Inbox Content -->
      <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
        <div class="inbox box" :class="{ 'intro-y': hasAnimation }">
          <div
            class="p-5 flex flex-col-reverse sm:flex-row text-gray-600 border-b border-gray-200 dark:border-dark-1"
          >
            <div
              class="flex items-center mt-3 sm:mt-0 border-t sm:border-0 border-gray-200 pt-5 sm:pt-0 mt-5 sm:mt-0 -mx-5 sm:mx-0 px-5 sm:px-0"
            >
              <div
                class="w-5 h-5 flex items-center justify-center dark:text-gray-300"
              >
                <VAction
                  icon="mark-as-read"
                  :label="$t('app.mark_as_all_read')"
                  @click="onClickMarkAsAllRead"
                />
              </div>

              <div
                class="w-5 h-5 ml-5 flex items-center justify-center dark:text-gray-300"
              >
                <VAction
                  icon="refresh"
                  :label="$t('app.refresh')"
                  @click="onClickRefresh"
                />
              </div>
            </div>
          </div>

          <div v-if="isLoading" class="flex justify-center">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
          </div>

          <div v-else-if="notifications.length === 0" class="p-2">
            <VAlert :text="emptyStateMsg" />
          </div>

          <div v-else class="overflow-x-auto sm:overflow-x-visible">
            <div
              v-for="(notification, index) in notifications"
              :key="index"
              :class="[
                { 'intro-y': hasAnimation },
                { 'rounded-b-md': index === notifications.length - 1 }
              ]"
              @click="onClickRedirect(notification)"
            >
              <div
                class="inbox__item inline-block sm:block text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1"
                :class="[
                  { 'inbox__item--active': !notification?.read_at },
                  { 'rounded-b-md': index === notifications.length - 1 }
                ]"
              >
                <div class="flex items-center px-5 py-3">
                  <div class="w-72 flex-none flex items-center mr-5">
                    <div
                      v-if="!notification?.read_at"
                      class="w-5 h-5 flex-none flex items-center justify-center text-gray-500"
                    >
                      <VAction
                        icon="mark-as-read"
                        :label="$t('app.mark_as_read')"
                        @click="onClickMarkAsRead(notification?.id)"
                      />
                    </div>

                    <div
                      v-else
                      class="w-5 h-5 flex-none flex items-center justify-center text-gray-500"
                    >
                      <VAction
                        icon="mark-as-unread"
                        :label="$t('app.notification_read')"
                      />
                    </div>

                    <div class="w-6 h-6 flex-none image-fit relative ml-5">
                      <img
                        :alt="notification?.data?.sent_by?.name"
                        class="rounded-full"
                        :src="getAvatar(notification)"
                      />
                    </div>

                    <div class="inbox__item--sender truncate ml-3">
                      {{ notification?.data?.sent_by?.name }}
                    </div>
                  </div>

                  <div class="w-64 sm:w-auto truncate">
                    <!-- eslint-disable-next-line -->
                    <span v-html="getNotificationMessage(notification)" />
                  </div>

                  <div
                    class="inbox__item--time whitespace-nowrap ml-auto pl-10"
                  >
                    {{
                      formatDate({
                        date: notification?.created_at,
                        displayTime: true
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FullPagination
          :pagination="pagination"
          @update:current_page="onUpdateCurrentPage"
          @update:per_page="onUpdatePerPage"
        />
        <!-- END: Inbox Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useRequest from "@/composables/useRequest";
import usePaginate from "@/composables/usePaginate";
import useDisplay from "@/composables/useDisplay";
import useAnimation from "@/composables/useAnimation";
// Components
import VTitle from "@/components/VTitle";
import VAction from "@/components/tables/VAction";
import VAlert from "@/components/VAlert";
import FullPagination from "@/components/tables/FullPagination";

export default {
  components: {
    VTitle,
    VAction,
    VAlert,
    FullPagination
  },
  setup() {
    // Misc
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const TABS = {
      ALL: "all",
      UNREAD: "unread"
    };
    const documentTitle = t("app.notifications", 2);

    // Data
    const currentTab = ref(TABS.ALL);
    const currentFilter = ref(null);
    const types = ref({});

    // Composables
    const { read, data, isLoading, pagination } = useReadMultiple();
    const { request } = useRequest();
    const { getAvatarURL, escapeHTML, formatDate } = useDisplay();
    const { hasAnimation } = useAnimation();

    // Computed
    const unreadNotifications = computed(() => {
      return store.getters["notifications/unread"];
    });
    const notifications = computed(() => {
      const tabs = {
        [TABS.ALL]: data.value,
        [TABS.UNREAD]: unreadNotifications.value
      };

      return tabs[currentTab.value];
    });
    const emptyStateMsg = computed(() => {
      const tabs = {
        [TABS.ALL]: t("app.no_notifications"),
        [TABS.UNREAD]: t("app.no_unread_notifications")
      };

      return tabs[currentTab.value];
    });
    const filters = computed(() => {
      return Object.keys(types.value).map(type => ({
        text: t(`notifications.${type}`),
        all: types.value[type]?.total,
        unread: types.value[type]?.unread,
        type
      }));
    });

    // Methods
    const getAllNotifications = async () => {
      await read({
        endpoint: "my.notifications.index",
        queryParams: getQueryParams()
      });
    };

    const getUnreadNotifications = async () => {
      await read({
        endpoint: "my.notifications.unread-all",
        queryParams: getQueryParams()
      });

      store.dispatch("notifications/setUnreadNotifications", data.value);
    };

    const getQueryParams = () => {
      if (currentFilter.value) {
        return { type: currentFilter.value };
      }

      return;
    };

    const getData = async type => {
      if (currentTab.value === TABS.ALL) {
        await getAllNotifications(type);
      } else {
        await getUnreadNotifications();
      }
    };

    const getNotificationMessage = notification => {
      const types = {
        // Attestations
        AttestationUploadRequest: t(
          "notifications.AttestationUploadRequest_msg",
          {
            attestation: notification?.data?.content?.attestation,
            training: notification?.data?.content?.training
          }
        ),
        AttestationApprovalRequest: t(
          "notifications.AttestationApprovalRequest_msg",
          {
            attestation: notification?.data?.content?.attestation,
            training: notification?.data?.content?.training
          }
        ),
        AttestationInstructorSubmission: t(
          "notifications.AttestationInstructorSubmission_msg",
          {
            attestation: notification?.data?.content?.attestation,
            training: notification?.data?.content?.training
          }
        ),
        AttestationApprovalDecision: t(
          "notifications.AttestationApprovalDecision_msg",
          {
            attestation: notification?.data?.content?.attestation,
            training: notification?.data?.content?.training
          }
        ),
        // Evaluations
        ObservationRequest: t("notifications.ObservationRequest_msg", {
          observation: notification?.data?.content?.evaluation,
          training: notification?.data?.content?.training
        }),
        ObservationScheduled: t("notifications.ObservationScheduled_msg", {
          observation: notification?.data?.content?.evaluation,
          training: notification?.data?.content?.training
        }),
        EvaluationCompletionApprovalRequest: t(
          "notifications.EvaluationCompletionApprovalRequest_msg",
          {
            evaluation: notification?.data?.content?.evaluation,
            training: notification?.data?.content?.training
          }
        ),
        // Collection Registrations
        CollectionRegistrationRequest: t(
          "notifications.CollectionRegistrationRequest_msg",
          {
            collection: notification?.data?.content?.collection
          }
        ),
        CollectionRegistrationAcceptanceDecision: t(
          "notifications.CollectionRegistrationAcceptanceDecision_msg",
          {
            collection: notification?.data?.content?.collection
          }
        ),
        CollectionRegistrationCompletionRequest: t(
          "notifications.CollectionRegistrationCompletionRequest_msg",
          {
            collection: notification?.data?.content?.collection
          }
        ),
        CollectionRegistrationCompletionApproval: t(
          "notifications.CollectionRegistrationCompletionApproval_msg",
          {
            collection: notification?.data?.content?.collection
          }
        ),
        CollectionRegistrationAdded: t(
          "notifications.CollectionRegistrationAdded_msg",
          {
            collection: notification?.data?.content?.collection
          }
        ),
        CollectionRegistrationCancelled: t(
          "notifications.CollectionRegistrationCancelled_msg",
          {
            collection: notification?.data?.content?.collection
          }
        ),
        // Registrations
        RegistrationRequest: t("notifications.RegistrationRequest_msg", {
          training: notification?.data?.content?.training
        }),
        RegistrationAcceptanceDecision: t(
          "notifications.RegistrationAcceptanceDecision_msg",
          {
            training: notification?.data?.content?.training
          }
        ),
        RegistrationCompletionRequest: t(
          "notifications.RegistrationCompletionRequest_msg",
          {
            training: notification?.data?.content?.training
          }
        ),
        RegistrationCompletionApproval: t(
          "notifications.RegistrationCompletionApproval_msg",
          {
            training: notification?.data?.content?.training
          }
        ),
        RegistrationAdded: t("notifications.RegistrationAdded_msg", {
          training: notification?.data?.content?.training
        }),
        RegistrationCancelled: t("notifications.RegistrationCancelled_msg", {
          training: notification?.data?.content?.training
        }),
        // Misc
        Send2FACode: t("notifications.Send2FACode_msg", {
          code: notification?.data?.code
        }),
        ResetPIN: t("notifications.ResetPIN_msg", {
          pin: notification?.data?.pin
        })
      };
      const [type] = notification?.type?.split("\\").slice(-1);

      return types[type];
    };

    const onClickRedirect = notification => {
      const types = {
        // Attestations
        AttestationUploadRequest: "teaching-requests-attestations",
        AttestationApprovalRequest: "teaching-approvals-attestations",
        AttestationInstructorSubmission: "learning-attestations-view",
        AttestationApprovalDecision: "learning-attestations-view",
        // Evaluations
        ObservationRequest: "teaching-requests-observations",
        ObservationScheduled: "learning-observations-view",
        EvaluationCompletionApprovalRequest: "teaching-approvals-evaluations",
        EvaluationApprovalDecision: "learning", //quiz or observation?
        // Collection Registrations & Registrations
        RegistrationRequested: "teaching-registrations-trainings-details",
        RegistrationAcceptanceDecision: "learning-registrations-details",
        RegistrationCompletionRequest: `teaching-registrations-trainings-details`,
        RegistrationCompletionApproval: "learning-registrations-details",
        RegistrationAdded: "learning-registrations-details",
        RegistrationCancelled: "learning-registrations-details"
      };
      const [type] = notification?.type?.split("\\").slice(-1);

      redirect(types[type], { id: notification?.data?.content?.id });
    };

    const redirect = (name, params) => {
      router.push({ name, params });
    };

    const markAsAllRead = async () => {
      await request({ endpoint: "my.notifications.mark-as-all-read" });
      store.dispatch("notifications/clearUnreadNotifications");
    };

    const isTabSelected = tab => isSelected(currentTab.value, tab);
    const isFilterSelected = filter => isSelected(currentFilter.value, filter);
    const isSelected = (currentValue, value) => currentValue === value;

    const updateTab = tab => {
      currentTab.value = tab;
    };

    const updateFilter = filter => {
      if (currentFilter.value === filter) {
        currentFilter.value = null;
        return;
      }

      currentFilter.value = filter;
    };

    const onClickUpdateFilter = async filter => {
      updateFilter(filter);
      await getData();
    };

    const markAsRead = async id => {
      await request({
        endpoint: "my.notifications.mark-as-read",
        pathParams: { id }
      });
      store.dispatch("notifications/removeUnreadNotification", id);
    };

    const onClickMarkAsRead = async id => {
      await markAsRead(id);
      await getData();
      await getTypes();
    };

    const onClickMarkAsAllRead = async () => {
      await markAsAllRead();
      await getData();
      await getTypes();
    };

    const onClickUpdateTab = async tab => {
      updateTab(tab);
      await getData();
    };

    const getTypes = async () => {
      const response = await request({
        endpoint: "my.notifications.summary",
        showToaster: false
      });

      types.value = response?.payload?.data ?? {};
    };

    const onClickRefresh = async () => {
      await getData();
      await getTypes();
    };

    const getAvatar = notification => {
      const { name, avatar } = notification?.data?.sent_by ?? {};

      if (!name || !avatar) {
        return;
      }

      if (name === "Chexpertise") {
        return avatar;
      }

      return getAvatarURL(avatar);
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
      await getTypes();
    });

    const { onUpdateCurrentPage, onUpdatePerPage } = usePaginate(
      pagination,
      getData
    );

    return {
      onClickRedirect,
      getNotificationMessage,
      documentTitle,
      emptyStateMsg,
      onClickUpdateTab,
      isTabSelected,
      currentFilter,
      isFilterSelected,
      onClickRefresh,
      TABS,
      currentTab,
      notifications,
      filters,
      onClickUpdateFilter,
      onClickMarkAsAllRead,
      onClickMarkAsRead,
      getAvatar,
      getData,
      // useReadMultiple
      isLoading,
      pagination,
      // useDisplay
      escapeHTML,
      formatDate,
      getAvatarURL,
      // usePaginate
      onUpdateCurrentPage,
      onUpdatePerPage,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
